import { Ref } from "vue";
import { useAuthStore } from "@/stores/auth-store";
import { useLayoutStore } from "@/stores/layout-store";
import { Tag } from "@/utils/types";

export function useAppHelpers() {
    const togglePassword = (
        element: HTMLElement | null,
        selector = ".password-input" as string
    ) => {
        if (element) {
            element.onclick = () => {
                var passwordInputs = document.querySelectorAll(
                    selector
                ) as NodeListOf<HTMLInputElement>;
                passwordInputs.forEach((elem) => {
                    if (elem.type === "password") {
                        elem.type = "text";
                        element.classList.remove("bx-show");
                        element.classList.add("bx-hide");
                    } else {
                        elem.type = "password";
                        element.classList.remove("bx-hide");
                        element.classList.add("bx-show");
                    }
                });
            };
        }
    };

    const switchClasses = (
        element: Element | HTMLElement | HTMLInputElement | null,
        addClass: string,
        removeClass: string
    ) => {
        if (element) {
            element.classList.add(addClass);
            element.classList.remove(removeClass);
        }
    };

    const htmlDecode = (data) => {
        var txt = document.createElement("textarea");
        txt.innerHTML = data;
        return txt.value;
    };

    const htmlEncode = (input) => {
        const textArea = document.createElement("textarea");
        textArea.innerText = input;
        return textArea.innerHTML.split("<br>").join("\n");
    };

    const deleteAllCookies = (cookieName = "", path = "") => {
        const cookies = document.cookie.split(";");
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i];
            const eqPos = cookie.indexOf("=");
            const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;

            if (cookie == "" || cookieName.trim() == name.trim()) {
            } else {
                continue;
            }
            deleteCookie(name, path);
        }
    };

    const deleteCookie = (name: string, path = "") => {
        if (path == "") {
            document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 GMT";
            document.cookie =
                name.trim() + "=; expires=Thu, 01 Jan 1970 00:00:00 GMT";
            document.cookie =
                name + "=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT";
            document.cookie =
                name.trim() +
                "=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT";
        } else {
            document.cookie =
                name +
                "=; path=" +
                path +
                "; expires=Thu, 01 Jan 1970 00:00:00 GMT";
            document.cookie =
                name.trim() +
                "=; path=" +
                path +
                "; expires=Thu, 01 Jan 1970 00:00:00 GMT";
        }
    };

    const getCookie = (name: string) => {
        const cookies = document.cookie.split(";");
        for (const cookie of cookies) {
            const [key, value] = cookie.split("=");
            if (key.trim() === name) {
                return decodeURIComponent(value.trim());
            }
        }
        return "";
    };

    const formatBytes = (bytes, decimals = 2) => {
        if (!+bytes) return "0 Bytes";

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${
            sizes[i]
        }`;
    };

    const timeAgo = (date: string) => {
        date = date.trim();
        if (!date.endsWith("000Z")) {
            date += ".000000Z";
        }
        if (date.indexOf(" ") >= 0) {
            date = date.replace(/\s+/g, "T");
        }

        const seconds = Math.floor(
            (new Date().valueOf() - new Date(date).valueOf()) / 1000
        );

        let interval = Math.floor(seconds / 31536000);
        if (interval >= 1) {
            return interval + " years ago";
        }

        interval = Math.floor(seconds / 2592000);
        if (interval >= 1) {
            return interval + " months ago";
        }

        interval = Math.floor(seconds / 86400);
        if (interval >= 1) {
            return interval + " days ago";
        }

        interval = Math.floor(seconds / 3600);
        if (interval >= 1) {
            return interval + " hours ago";
        }

        interval = Math.floor(seconds / 60);
        if (interval >= 1) {
            return interval + " minutes ago";
        }

        if (seconds < 10) return "just now";

        return Math.floor(seconds) + " seconds ago";
    };

    const getProfilePic = (
        link = "" as string | null | undefined,
        fullname = "Test User",
        id = null as null | number,
        tags = [] as Array<Tag>
    ) => {
        try {
            if (!link) {
                
                let name = fullname
                    .split(" ")
                    .map((segment) => segment.trim().substring(0, 1))
                    .join(" ")
                    .trim();

                    let tagColor = null as null | string;
                    let tagColorStriped = null as null | string;
                    if(tags.length > 0){
                        let tag = tags[tags.length - 1] as Tag;
                        tagColor = tag ? tag.color as string : null;

                        if(tagColor){
                            //remove # from the start of tagColor string
                            tagColorStriped = tagColor.substring(1);
                        }
                    }

                if (id && tagColor) {
                    // let colors = generateColors(fullname + " " + id);
                    let foregroundColor = calculateContrastColor(tagColor)
                    return (
                        "https://ui-avatars.com/api/?name=" +
                        encodeURIComponent(name) +
                        "&color=" +
                        foregroundColor +
                        "&background=" +
                        tagColorStriped
                    );
                } else {
                    return (
                        "https://ui-avatars.com/api/?name=" +
                        encodeURIComponent(name) +
                        "&color=7F9CF5&background=31445A"
                    );
                }
            }
            return link;
        } catch (e) {
            return "https://ui-avatars.com/api/?name=Name&color=7F9CF5&background=31445A";
        }
    };

    const copyText = (text) => {
        if (navigator.clipboard) {
            navigator.clipboard
                .writeText(text)
                .then(() => {})
                .catch((err) => {});
        } else {
            const tempTextArea = document.createElement("textarea");
            tempTextArea.value = text;
            document.body.appendChild(tempTextArea);
            tempTextArea.select();
            tempTextArea.setSelectionRange(0, 99999);
            document.execCommand("copy");
            document.body.removeChild(tempTextArea);
        }
    };

    const generateUniqueString = (length) => {
        const characters =
            "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
        let result = "";

        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            result += characters.charAt(randomIndex);
        }
        const timestamp = Date.now().toString();
        result += timestamp;

        return result;
    };

    const phoneNumberFormatter = (val: string | null | undefined) => {
        if (val) {
            let cleanNumber = val.replace(/[\t\s_\-\(\)\[\]\{\}]/g, "");
            cleanNumber = cleanNumber.replace(/\D/g, "");
            cleanNumber = cleanNumber.trim();

            if (cleanNumber.length === 10) {
                cleanNumber = "+1" + cleanNumber;
            }

            if (cleanNumber.length === 11 && !cleanNumber.startsWith("+")) {
                cleanNumber = `+1 ${cleanNumber.slice(
                    1,
                    4
                )}-${cleanNumber.slice(4, 7)}-${cleanNumber.slice(7)}`;
                return cleanNumber;
            } else if (
                cleanNumber.length === 12 &&
                cleanNumber.startsWith("+")
            ) {
                const withoutPlus = cleanNumber.slice(1);
                cleanNumber = `+1 ${withoutPlus.slice(
                    1,
                    4
                )}-${withoutPlus.slice(4, 7)}-${withoutPlus.slice(7)}`;
                return cleanNumber;
            } else {
                return val;
            }
        }
        return val;
    };

    const cleanPhoneNumber = (number) => {
        let cleanedNumber = number.replace(/\s+/g, "");
        cleanedNumber = cleanedNumber.replace(/[\t\s_\-\(\)\[\]\{\}]/g, "");

        const startsWithPlus = cleanedNumber.startsWith("+");
        if (cleanedNumber.length === 10 && !startsWithPlus) {
            cleanedNumber = "1" + cleanedNumber;
        }

        if (!startsWithPlus) {
            cleanedNumber = "+" + cleanedNumber;
        }

        const lengthOfNumber = cleanedNumber.length;
        if (lengthOfNumber < 11 || lengthOfNumber > 13) {
            return "";
        }

        return cleanedNumber;
    };

    const isValidCleanedPhoneNumber = (number) => {
        const cleanedNumber = number
            .replace(/\s+/g, "")
            .replace(/[\t\s_\-\(\)\[\]\{\}]/g, "");

        const startsWithPlus = cleanedNumber.startsWith("+");

        if (cleanedNumber.length === 10 && !startsWithPlus) {
            return true;
        }

        if (!startsWithPlus) {
            return false;
        }

        const lengthOfNumber = cleanedNumber.length;
        return lengthOfNumber >= 11 && lengthOfNumber <= 13;
    };

    const isValidPhoneNumberFormat = (phoneNumber: string): boolean => {
        // Remove any non-digit characters
        const cleanNumber = phoneNumber.replace(/\D/g, "");

        // Check if the cleaned number matches the expected format
        const regex = /^(\+\d{1,2} )?\d{3}-\d{3}-\d{4}$/;
        return regex.test(cleanNumber);
    };

    const timeSplited = (val: string) => {
        if (val) {
            const [hours, minutes, seconds] = val.split(":");
            const date = new Date();
            date.setHours(parseInt(hours));
            date.setMinutes(parseInt(minutes));
            date.setSeconds(parseInt(seconds));

            const twelveHourTime = date.toLocaleTimeString("en-US", {
                hour: "numeric",
                minute: "2-digit",
                hour12: true,
            });
            if (twelveHourTime) {
                const [formattedHours, formattedMinutes, amPm] =
                    twelveHourTime.split(/:| /);
                return [formattedHours, formattedMinutes, amPm];
            }
        }
        return ["12", "0", "AM"];
    };

    const contactStatusFormatter = (val: string) => {
        switch (val) {
            case "active":
                return `<span class='fs-12 text-capitalize badge rounded-pill badge-soft-success'>${val}</span>`;
            case "blocked":
                return `<span class='fs-12 text-capitalize badge rounded-pill badge-soft-danger'>${val}</span>`;
            case "user optout":
            case 'user optout dnc':
            case 'stop word':
                return `<span class='fs-12 text-capitalize badge rounded-pill badge-soft-warning'>${val}</span>`;
            case "invalid":
                return `<span class='fs-12 text-capitalize badge rounded-pill badge-soft-info'>${val}</span>`;
            case "landline":
                return `<span class='fs-12 text-capitalize badge rounded-pill badge-soft-primary'>${val}</span>`;
            default:
                return `<span class='fs-12 text-capitalize badge rounded-pill badge-soft-dark'>${val}</span>`;
        }
    };

    const userStatusFormatter = (val: string | undefined) => {
        switch (val) {
            case "active":
                return `<span class='fs-12 text-capitalize badge rounded-pill badge-soft-success'>${val}</span>`;
            case "blocked":
                return `<span class='fs-12 text-capitalize badge rounded-pill badge-soft-danger'>${val}</span>`;
            case "unverified":
                return `<span class='fs-12 text-capitalize badge rounded-pill badge-soft-warning'>${val}</span>`;
            default:
                return `<span class='fs-12 text-capitalize badge rounded-pill badge-soft-info'>${val}</span>`;
        }
    };

    const utcToLocalTimeInMilliSeconds = (utcTimeString) => {
        const utcDate = new Date(utcTimeString);
        const utcTimeMilliseconds = utcDate.getTime();
        const userTimezoneOffset = new Date().getTimezoneOffset();
        const localTimeMilliseconds =
            utcTimeMilliseconds - userTimezoneOffset * 60 * 1000;
        return localTimeMilliseconds;
    };

    const dateFormatter = (val) => {
        let auth = useAuthStore();
        if (val && auth.user) {
            if (!val.endsWith("000Z")) {
                val = val.replace(" ", "T") + ".000000Z";
            }
            let date = new Date(val);
            const userTimezone = auth.user.timezone;
            let ctz = Intl.DateTimeFormat().resolvedOptions().timeZone;
            let options: Intl.DateTimeFormatOptions = {
                timeZone:
                    userTimezone && ctz == userTimezone ? userTimezone : ctz,
            };
            return date.toLocaleDateString("en-US", options);
        }
        return "";
    };

    const dateTimeFormatter = (val) => {
        let auth = useAuthStore();
        if (val && auth.user) {
            if (!val.endsWith("000Z")) {
                val = val.replace(" ", "T") + ".000000Z";
            }
            let date = new Date(val);
            const userTimezone = auth.user.timezone;
            let ctz = Intl.DateTimeFormat().resolvedOptions().timeZone;
            let options: Intl.DateTimeFormatOptions = {
                timeZone:
                    userTimezone && ctz == userTimezone ? userTimezone : ctz,
            };
            return date.toLocaleString("en-US", options);
        }
        return "";
    };

    const capitalizeString = (string) => {
        // Split string at spaces
        const words = string.split(" ");
        // loop over string and capitalize first letter of each word
        const capitalizedWords = words.map((word) => {
            return word.charAt(0).toUpperCase() + word.slice(1);
        });
        return capitalizedWords.join(" ");
    };

    const generateColors = (string) => {
        let hash = 0;
        for (let i = 0; i < string.length; i++) {
            hash = string.charCodeAt(i) + (hash << 1);
        }

        let backgroundColor = (hash & 0x00ffffff).toString(16).toUpperCase();
        while (backgroundColor.length < 6) {
            backgroundColor = "0" + backgroundColor;
        }      
        
        backgroundColor = colorBrightness(backgroundColor, 0.6);

        let foregroundColor = "FFFFFF";
        // let foregroundColor = calculateContrastColor(backgroundColor);

        // let layout = useLayoutStore();
        // if (layout.mode == "dark") {
        //     backgroundColor = colorBrightness(backgroundColor, 0.6);
        // } else {
        //     foregroundColor = '000000';
        //     backgroundColor = colorBrightness(backgroundColor, 1.6);
        // }

        return {
            background: backgroundColor,
            color: foregroundColor,
        };
    };

    const colorBrightness = (hexColor, factor=0.6) => {
        // Adjust this factor to control the darkness level
        // 0.6 is a good value for most cases for darken color
        // 1.4 is a good value for most cases for lighten color

        let r = parseInt(hexColor.substring(1, 3), 16);
        let g = parseInt(hexColor.substring(3, 5), 16);
        let b = parseInt(hexColor.substring(5, 7), 16);
    
        // Darken each RGB component
        r = Math.floor(r * factor);
        g = Math.floor(g * factor);
        b = Math.floor(b * factor);
    
        // Convert back to hexadecimal format
        let color = (r << 16) | (g << 8) | b;
        let finalColor = color.toString(16).padStart(6, '0').toUpperCase();
    
        return finalColor;
    };

    const calculateContrastColor = (hexColor) => {
        let r = parseInt(hexColor.substring(1, 3), 16);
        let g = parseInt(hexColor.substring(3, 5), 16);
        let b = parseInt(hexColor.substring(5, 7), 16);

        // let luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
        let luminance = (0.2126 * r + 0.7152 * g + 0.0722 * b) / 255;

        return luminance > 0.5 ? "000000" : "FFFFFF";
    };

    const getContactName = (contact) => {
        let formattedName = contact.first_name.split(' ')[0];
        if (contact.last_name) {
            formattedName += ' ' + contact.last_name.charAt(0).toUpperCase() + '.';
        }
        return formattedName;
    }

    return {
        togglePassword,
        switchClasses,
        htmlDecode,
        htmlEncode,
        deleteAllCookies,
        deleteCookie,
        getCookie,
        formatBytes,
        timeAgo,
        getProfilePic,
        copyText,
        generateUniqueString,
        phoneNumberFormatter,
        isValidPhoneNumberFormat,
        cleanPhoneNumber,
        isValidCleanedPhoneNumber,
        timeSplited,
        contactStatusFormatter,
        userStatusFormatter,
        utcToLocalTimeInMilliSeconds,
        dateFormatter,
        dateTimeFormatter,
        capitalizeString,
        generateColors,
        calculateContrastColor,
        getContactName
    };
}
